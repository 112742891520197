:root {
  --color1: #9A3B3B;
  --color2: #F9DEC9;
  --button: #38A1DB;
  --buttonshadow: #2993C1;
  --buttonborder: #4AB1E5;
  --font1:#F9DEC9;
  --font2:#9A3B3B;
  --background: #252B48;
  --backgroundlight: #D1B8A8;
  --navbar: #1d1d36
  ;
}



#board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: 90vw;
  max-width: 800px;
  height: 90vw;
  max-height: 800px;
  outline: 5px solid var(--navbar); /* This will give the appearance of the border */
  aspect-ratio: 1/1;
  margin: auto;
}

/* Tile Styling */
.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  position: relative;
  box-sizing: border-box;
}
.highlight-tile {
  border: 3px solid red; /* or whatever styling you'd like for highlighting */
}

.black-tile {
  background-color: var(--color1)
}

.white-tile {
  background-color: var(--color2)
}

/* Coordinates Styling */



body {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
}

.container {
  display: flex;
  margin-top: 40px;
  justify-content: center;  
  width: 100%;
  box-sizing: border-box;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 10px; /* button spacing */
  margin-left: 80px;
  height: 800px;
  overflow-y: auto; 
}



/* ChessBoard */
.board-and-mic {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  justify-content: space-between; 
  aspect-ratio: 1/1;
  max-width: 100%;
  box-sizing: border-box;
}


.Mic{
  all: unset;
  size: 20px;
  color: var(--button);
}

.Speaker{
  all:unset;
  size: 20px;
  color: var(--button);

}


/* Pieces */
.bishop {
  width: 80%; 
  height: 80%; 
  display: block;
  margin: auto; 
}
.knight {
  width: 80%; 
  height: 80%; 
  display: block;
  margin: auto; 
}
.rook {
  width: 80%; 
  height: 80%; 
  display: block;
  margin: auto; 
}

.queen {
  width: 80%; 
  height: 80%; 
  display: block;
  margin: auto; 
}

.selected {
  border: 2px solid blue;  
}

.hidden-board {
  visibility:hidden; 
}

.coordinate {
  position: absolute;
  font-size: 40px; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  font-weight: 700;
}

.white-tile .coordinate {
  color: var(--color1);
}


.black-tile .coordinate {
  color: var(--color2);
}

/* control buttons */
.myButton {
  all: unset;
}

.ButtonContainer {
  position: relative;
  width: 200px;  
  height: 50px;  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  color: var(--font1);
  text-decoration: none;
 
  border: solid 1px var(--button);
  background: var(--button);
  text-align: center;  
  margin: 12px;

  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;

  -webkit-box-shadow: 0px 6px 0px var(--buttonshadow);
  -moz-box-shadow: 0px 6px 0px var(--buttonshadow);
  box-shadow: 0px 6px 0px var(--buttonshadow);
}

.ButtonContainer:active {
  -webkit-box-shadow: 0px 2px 0px var(--buttonshadow);
  -moz-box-shadow: 0px 2px 0px var(--buttonshadow);
  box-shadow: 0px 2px 0px var(--buttonshadow);
  position: relative;
  top: 4px;
}


/* Piece Img */

.Piece-selection {
  display: flex;
  justify-content: center;
  gap: 10px; 
}

.Piece-selection img {
  width: 100px; 
  cursor: pointer;
  border: solid 1px var(--buttonborder);
  
  background-color:var(--button);

  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;

  -webkit-box-shadow: 0px 6px 0px var(--buttonshadow);
  -moz-box-shadow: 0px 6px 0px var(--buttonshadow);
  box-shadow: 0px 6px 0px var(--buttonshadow);
}

.Piece-selection img:active,
.Piece-selection img.selected {
  -webkit-box-shadow: 0px 2px 0px var(--buttonshadow);
  -moz-box-shadow: 0px 2px 0px var(--buttonshadow);
  box-shadow: 0px 2px 0px var(--buttonshadow);
  position: relative;
  top: 4px;
  
}

/* Position selector dropdown */

ul.dropdown{ 
  position: relative; 
  width: 100%; 
}

ul.dropdown li{ 
  font-weight: bold; 
  float: left; 
  width: 180px; 
  position: relative;
  background: #ecf0f1;
}

ul.dropdown a:hover{ 
  color: #000; 
}

ul.dropdown li a { 
  display: block; 
  padding: 20px 8px;
  color: #34495e; 
  position: relative; 
  z-index: 2000; 
  text-align: center;
  text-decoration: none;
  font-weight: 300;
}

ul.dropdown li a:hover,
ul.dropdown li a.hover{ 
  background: #3498db;
  position: relative;
  color: #fff;
}


ul.dropdown ul{ 
 display: none;
 position: absolute; 
  top: 0; 
  left: 0; 
  width: 180px; 
  z-index: 1000;
}

ul.dropdown ul li { 
  font-weight: normal; 
  background: #f6f6f6; 
  color: #000; 
  border-bottom: 1px solid #ccc; 
}

ul.dropdown ul li a{ 
  display: block; 
  color: #34495e !important;
  background: #eee !important;
} 

ul.dropdown ul li a:hover{
  display: block; 
  background: #3498db !important;
  color: #fff !important;
} 

.drop > a{
  position: relative;
}

.drop > a:after{
  content:"";
  position: absolute;
  right: 10px;
  top: 40%;
  border-left: 5px solid transparent;
  border-top: 5px solid #333;
  border-right: 5px solid transparent;
  z-index: 999;
}

.drop > a:hover:after{
  content:"";
   border-left: 5px solid transparent;
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
}

.control-container {
  background-color: var(--backgroundlight);  
  padding: 11px;
  margin-bottom: 10px;
  border: #000 4px solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: var(--font2);
  
}

.control-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #9A3B3B;
}

.MSPK {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.Mic, button.Speaker {
  margin: 0 10px; 
}

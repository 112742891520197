
body {
    padding: 0;
    margin: 0;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
   
    height: 50px;
    background-color: #3B2A1F;  
    padding: 0 5%;
}

nav .logo { 
    float: left;
    
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: var(--font1);
}

nav .links {
    float: right;
    padding: 0;
    margin: 0;
   
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

nav .links li {
    list-style: none;
}

nav .links a {
    display: block;
    padding: 1em;
    font-size: 16px;
    
    color: var(--font1);
    text-decoration: none;
    position: relative;
}

nav .links a:hover {
    color: white;
}

nav .links a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

nav .links a:hover::before {
    visibility: visible;
    transform: scaleX(1);
    color: white;
}

#nav-toggle {
     position: absolute;
     top: -50px;
}

nav .icon-burger {
    display: none;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
}

nav .icon-burger .line {
    width: 30px;
    height: 3px;
    background-color: var(--font1);
    margin: 5px;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    nav .logo {
        float: none;
        width: auto;
        justify-content: center;
    }
    nav .links {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 50px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: rgba(0, 0, 0, .8);
        overflow: hidden;
        transition: all .5s ease-in-out;
    }
    nav .links a {
        font-size: 20px;
    }
    nav :checked ~ .links {
        bottom: 0;
    }
    nav .icon-burger {
        display: block;
    }
    nav :checked ~ .icon-burger .line:nth-child(1) {
        transform: translateY(8px) rotate(225deg);
    }
    nav :checked ~ .icon-burger .line:nth-child(3) {
        transform: translateY(-8px) rotate(-225deg);
    }
    nav :checked ~ .icon-burger .line:nth-child(2) {
        opacity: 0;
    }
}
